<template>
  <b-tabs content-class="mt-3">
    <domestic-supplier :entity-id="entityId" />
    <domestic-customer :entity-id="entityId" />
    <foreign-supplier :entity-id="entityId" />
    <foreign-customer :entity-id="entityId" />
<!--    <bank-account-statements :entity-id="entityId" />-->
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import DomesticCustomer from "@/views/pages/_components/_tab/_client/_invoice/domestic-customer.vue";
import DomesticSupplier from "@/views/pages/_components/_tab/_client/_invoice/domestic-supplier.vue";
import ForeignCustomer from "@/views/pages/_components/_tab/_client/_invoice/foreign-customer.vue";
import ForeignSupplier from "@/views/pages/_components/_tab/_client/_invoice/foreign-supplier.vue";
import BankAccountStatements from "@/views/pages/_components/_tab/_client/_bankAccount/bank-account-statements.vue";

export default {
  components: {
    BTabs,
    DomesticCustomer,
    DomesticSupplier,
    ForeignCustomer,
    ForeignSupplier,
    BankAccountStatements
  },
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
