<template>
  <b-tab
      lazy
  >
    <template #title>
      <mdicon :name="'ReceiptTextMinus' || 'CheckboxBlankCircleOutline'"
              :size="21"
      />
      <strong>
        {{ $t('tab.title.domestic-supplier-invoices') }}
      </strong>
    </template>
    <document-table  direction="INBOUND" :entity-id="this.entityId" fod="domestic" />
  </b-tab>
</template>

<script>
import {
  BTab,
} from 'bootstrap-vue'
import DocumentTable from '@/views/pages/client/document/table/Desktop.vue'

export default {
  components: {
    BTab,
    DocumentTable
  },
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
}
</script>
