<template>
  <div>
    <div class="custom-search d-flex" :class="{'justify-content-end': fod === 'domestic', 'justify-content-between': fod === 'foreign' && this.$acl.canCreatePermission(role)}">
      <b-form-group v-if="fod === 'foreign' && this.$acl.canCreatePermission(role)">
        <div class="d-flex align-items-center" v-if="direction === 'OUTBOUND'">
          <b-button
              variant="success"
              v-b-tooltip.hover
              :title="$t('label.button.add')"
              @click="$router.push({name: 'add-client-outbound-invoice', params: { id: entityId }})"
          >Hozzáadás</b-button>
        </div>
        <div class="d-flex align-items-center" v-if="direction === 'INBOUND'">
          <b-button
              variant="success"
              v-b-tooltip.hover
              :title="$t('label.button.add')"
              @click="$router.push({name: 'add-client-inbound-invoice', params: { id: entityId }})"
          >Hozzáadás</b-button>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('label.search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('label.searchPlaceholder')"
              type="text"
              class="d-inline-block"
              @input="globalSearch"
          />
        </div>
      </b-form-group>
    </div>
    <b-overlay
        :show="loading"
        :opacity="0.1"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          @on-row-click="onRowClick"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
      >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            {{ $t('table.list.empty') }}
          </div>
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <span
              v-if="props.column.field === 'fileId'"
          >
<!--                -->
            <img
                v-if="props.row.fileId"
                v-b-tooltip.hover
                :alt="props.row.file.filename"
                :title="props.row.file.filename"
                :src="require('@/assets/images/icons/invoice/check.svg')"
                @click.stop="downloadFile(props.row.fileId, props.row.file.filename)"
            />
            <img
                v-else
                v-b-tooltip.hover
                :alt="$t('file.notFound')"
                :title="$t('file.notFound')"
                :src="require('@/assets/images/icons/invoice/times.svg')"
            />
          </span>

          <span
              v-else-if="props.column.field === 'appearance'"
          >
            <img
                v-b-tooltip.hover
                :alt="$t('invoices.appearances.'+props.row.appearance)+' '+$t('invoices.categories.'+props.row.category)+' '+$t('invoices.operations.'+props.row.operation)"
                :title="$t('invoices.appearances.'+props.row.appearance)+' '+$t('invoices.categories.'+props.row.category)+' '+$t('invoices.operations.'+props.row.operation)"
                :src="require('@/assets/images/icons/invoice/'+props.row.category+'-'+props.row.appearance+'-'+props.row.operation+'.svg')"
            />
          </span>

          <span class="text-nowrap"
                v-else-if="props.column.field === 'netAmount'"
          >
            <span class="text-nowrap">
              {{ $formatters.formatPriceText(props.row.netAmount, props.row.currency) }}
            </span>
          </span>

          <span class="text-nowrap"
                v-else-if="props.column.field === 'vatAmount'"
          >
            <span class="text-nowrap">
              {{ $formatters.formatPriceText(props.row.vatAmount, props.row.currency) }}
            </span>
          </span>

          <span v-else-if="props.column.field === 'supplierName'">
            <span v-if="props.row.direction.toLowerCase() === 'outbound'">{{ props.row.customerName }}</span>
            <span v-else>{{ props.row.supplierName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span v-if="$acl.canEditPermission(role) && !props.row.fileId">
              <b-button
                  size="sm"
                  variant="primary"
                  class="ml-1"
                  v-b-tooltip.hover
                  :title="$t('label.button.upload')"
                  @click.stop="showUploadInvoiceModal(props.row.fileId, props.row.id)"
              >
                <feather-icon
                    icon="UploadIcon"
                />
              </b-button>
            </span>
            <span v-if="$acl.canEditPermission(role) && props.row.fileId">
              <b-button
                  size="sm"
                  variant="primary"
                  class="ml-1"
                  v-b-tooltip.hover
                  :title="$t('label.button.replace')"
                  @click.stop="showUploadInvoiceModal(props.row.fileId, props.row.id)"
              >
                <feather-icon
                    icon="RepeatIcon"
                />
              </b-button>
            </span>
            <span v-if="props.row.fileId">
              <b-button
                  size="sm"
                  variant="warning"
                  class="ml-1"
                  v-b-tooltip.hover
                  :title="$t('label.button.download')"
                  @click.stop="downloadFile(props.row.fileId, props.row.file.filename)"
              >
                <feather-icon
                    icon="DownloadIcon"
                />
              </b-button>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @change="handlePageChange"
              />
              <span class="text-nowrap ">{{ $t('table.list.pagination', {total: dataFromServer.total}) }}</span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="dataFromServer.total"
                  :per-page="dataFromServer.per_page"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
    <upload-invoice
        ref="uploadInvoiceModal"
        @refresh-invoices="initData"
    />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BOverlay,
  BTooltip,
  VBTooltip
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import uploadInvoice from '@/views/pages/_components/_modal/_invoice/uploadInvoice.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
    BTooltip,
    uploadInvoice
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    entityId: {
      type: String|Number,
      required: true
    },
    direction: {
      type: String,
      required: true
    },
    fod: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      role: 'invoice',
      pageLength: 10,
      dir: false,
      pages: ['10', '20', '50'],
      columns: [
        {
          label: this.$t('form.fileId'),
          field: 'fileId',
          sortable: false,
          filterOptions: {
            enabled: false,
          },
          tdClass: 'vgt-center-align align-middle'
        },
        {
          label: this.$t('form.appearance'),
          field: 'appearance',
          sortable: false,
          filterOptions: {
            enabled: false,
          },
          tdClass: 'vgt-center-align align-middle'
        },
        {
          label: this.$t('form.invoiceNumber'),
          field: 'invoiceNumber',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.invoiceNumber'),
          },
        },
        {
          label: this.$t('form.partner'),
          field: 'supplierName',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.partner'),
          },
        },
        {
          label: this.$t('form.issueDate'),
          field: 'issueDate',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.issueDate'),
          },
        },
        {
          label: this.$t('form.deliveryDate'),
          field: 'deliveryDate',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.deliveryDate'),
          },
        },
        {
          label: this.$t('form.paymentDate'),
          field: 'paymentDate',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.paymentDate'),
          },
        },
        {
          label: this.$t('form.netAmount'),
          field: 'netAmount',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.netAmount'),
          },
        },
        {
          label: this.$t('form.vatAmount'),
          field: 'vatAmount',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.vatAmount'),
          },
        },
      ],
      rows: [],
      page: 1,
      dataFromServer: [],
      filterData: [],
      searchTerm: '',
      sortBy: {
        field: 'id',
        type: 'desc',
      },
      columnFilters: [],
      loading: false,
    }
  },
  created() {
    if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
      const actions = [
        {
          label: this.$t('table.header.actions'),
          field: 'action',
          sortable: false,
          width: '10rem',
          tdClass: 'text-center'
        },
      ]

      this.columns = [...this.columns, ...actions]
    }

    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      const searchParams = {
        page: parseInt(this.page),
        limit: parseInt(this.pageLength),
        query: this.searchTerm,
        columnFilters: {
          clientId: this.entityId,
          direction: this.direction,
          fod: this.fod,
          ...this.columnFilters
        },
        sort: this.sortBy,

      }


      this.$store.dispatch('fetchInvoices', searchParams).then(response => {
        this.rows = response.data.entities.data
        this.dataFromServer = response.data.entities
        this.page = this.dataFromServer.current_page
        this.pageLength = parseInt(this.dataFromServer.per_page)
      }).finally(() => {
        this.loading = false
      })
    },
    onRowClick(params) {
      if (params.row.fileId) {
        this.downloadFile(params.row.fileId, params.row.file.filename)
      } else {
        this.showUploadInvoiceModal(params.row.fileId, params.row.id)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.initData()
    },
    handlePageChange(active) {
      this.pageLength = active
      this.page = 1
      this.handleChangePage()
    },
    globalSearch(params) {
      this.page = 1
      this.query = params.searchTerm
      this.initData()
    },
    onSortChange(params) {
      this.page = 1
      this.sortBy = params[0]
      this.initData()
    },
    onColumnFilter(params) {
      this.page = 1
      this.columnFilters = params.columnFilters
      this.initData()
    },
    downloadFile(id, filename) {
      this.$store.dispatch('downloadFile', id).then(response => {
        const blob = new Blob([response.data], {type: response.headers['Content-Type']})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)

        this.$helpers.showSuccessToast()
      })
    },
    showUploadInvoiceModal(fileId, invoiceId) {
      this.$refs.uploadInvoiceModal.formData.fileId = fileId
      this.$refs.uploadInvoiceModal.formData.invoiceId = invoiceId
      this.$refs.uploadInvoiceModal.initData()
      this.$bvModal.show('form-upload-invoice-modal')
    }
  },
}
</script>
