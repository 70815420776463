<template>
  <b-modal
      id="form-upload-invoice-modal"
      ref="form-upload-invoice-modal"
      cancel-variant="outline-secondary"
      :ok-title="$t('label.button.upload')"
      :cancel-title="$t('label.button.cancel')"
      @ok="handleOk"
      centered
      size="lg"
      :title="$t('card.title.upload.invoice')"
  >
    <b-alert show variant="primary" class="mb-1">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" size="24"/>
        {{ $t('card.description.file.upload') }}
      </div>
    </b-alert>

    <b-media v-if="formData.file !== ''">
      <template #aside>
        <mdicon v-if="formData.file.mimeType === 'application/pdf'" :name="'FilePdfBox' || 'CheckboxBlankCircleOutline'"
                :size="70"/>
        <mdicon v-if="formData.file.mimeType === 'image/bmp'" :name="'Image' || 'CheckboxBlankCircleOutline'"
                :size="70"/>
        <mdicon v-if="formData.file.mimeType === 'image/gif'" :name="'FileGifBox' || 'CheckboxBlankCircleOutline'"
                :size="70"/>
        <mdicon v-if="formData.file.mimeType === 'image/jpeg'" :name="'FileJpgBox' || 'CheckboxBlankCircleOutline'"
                :size="70"/>
        <mdicon v-if="formData.file.mimeType === 'image/png'" :name="'FilePngBox' || 'CheckboxBlankCircleOutline'"
                :size="70"/>
      </template>

      <h2>{{ formData.file.filename }}</h2>
      <p>{{ formData.file.humanReadableSize }}</p>

      <!-- b-[Optional: add media children here for nesting] -->
    </b-media>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col :sm="file ? 10 : 12">
            <b-form-file
                v-model="file"
                :placeholder="$t('label.files.choose')"
                :drop-placeholder="$t('label.files.drop')"
                :browse-text="$t('label.files.browse')"
                accept="
                    application/pdf,
                    image/bmp,
                    image/gif,
                    image/jpeg,
                    image/png
                  "
            />
          </b-col>
          <b-col v-if="file" sm="2">
            <b-button
                variant="danger"
                class="float-left mr-1"
                @click.prevent="() => {file = null}"
            >
              <feather-icon
                  icon="Trash2Icon"
                  size="15"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import BCardCode from "@core/components/b-card-code";
import {BForm, BCol, BRow, BFormFile, BButton, BAlert, BMedia} from "bootstrap-vue"

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BRow,
    BFormFile,
    BButton,
    BAlert,
    BMedia,
  },
  data() {
    return {
      role: 'invoice',
      file: null,
      formData: {
        fileId: '',
        invoiceId: '',
        file: '',
      }
    }
  },
  methods: {
    initData() {
      this.formData.file = ''
      if (this.formData.fileId) {
        this.$store.dispatch('fetchFile', this.formData.fileId).then(response => {
          this.formData.file = response.data.entity
        })
      }
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const fd = new FormData();
          fd.append('file', this.file)
          fd.append('invoiceId', this.formData.invoiceId)

          this.$store.dispatch('uploadInvoice', fd).then(() => {
            this.$helpers.showSuccessToast()
            this.$emit('refresh-invoices')
            this.$nextTick(() => {
              this.$bvModal.hide('form-upload-invoice-modal')
            })
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else if (error.response.status === 413) {
              this.$helpers.showErrorToast(this.$t('text.api.response.tooBigFile'))
            } else if(error.response.status === 500){
              this.$helpers.showErrorToast(error.response.data.message)
            }else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    }
  },
}
</script>
