<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.list.document') }}: {{ client.companyName }}
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.list.document') }}
      </b-card-text>
    </b-card>

    <b-card>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <documents :entity-id="$route.params.id" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardText, BButton
} from 'bootstrap-vue'
import Documents from "@/views/pages/_components/_tabs/_client/documents.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    Documents,
  },
  data() {
    return {
      role: 'invoice',
      client: '',
    }
  },
  created(){
    this.initData()
  },
  methods: {
    initData(){
      this.$store.dispatch('fetchClient', this.$route.params.id).then(response => {
        this.client = response.data.entity
      })
    }
  }
}
</script>
