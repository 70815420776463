<template>
  <b-tab
      lazy
  >
    <template #title>
      <mdicon :name="'Bank' || 'CheckboxBlankCircleOutline'"
              :size="21"
      />
      <strong>
        {{ $t('tab.title.bank-account-statements') }}
      </strong>
    </template>
    bankszámlakivonatok
  </b-tab>
</template>

<script>
import {BTab} from "bootstrap-vue"

export default {
  components: {
    BTab,
  },
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      role: 'client',
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      console.log("tab initialized")
    },
  },
}
</script>
